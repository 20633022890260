#single-document {
  .container {
    max-width: 970px;
    .date {
      margin-bottom: 1rem;
      display: block;
    }
    h2 {
      margin-bottom: 3rem;
    }
    p {
      line-height: 1.2;
    }
  }
  .styledText {
    * {
      font-family: "Gilroy", sans-serif !important;
    }
    span,
    p,
    ul {
      color: var(--black);
      line-height: 1.5rem !important;
    }
    a {
      color: var(--blueColor);
    }
  }
}
.allegati-list {
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
