.chi-siamo-codice-etico-page {
  .row {
    display: flex;
    & > * + * {
      margin-left: 5rem;
    }
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      & > * + * {
        margin-left: 0;
      }
    }

    .left {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      p {
        margin-bottom: 2rem;
        line-height: 1.5;
        font-size: 1.2rem;
        width: 65%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .right {
      position: relative;
      width: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .blue,
      .green,
      .orange,
      .box {
        position: absolute;
      }

      .blue {
        background-color: #00ccff;
        height: 2.5rem;
        width: 2.5rem;
        top: -1.25rem;
        left: 3rem;
        border-radius: 50%;
      }

      .green {
        background-color: #53de69;
        height: 2rem;
        width: 2rem;
        top: 60%;
        left: -3.5rem;
        border-radius: 50%;
        z-index: 1;
      }

      .orange {
        background-color: #ff8452;
        height: 2.5rem;
        width: 2.5rem;
        bottom: 3rem;
        right: -1.25rem;
        border-radius: 50%;
      }
      @media screen and (max-width: 768px) {
        .box,
        .blue,
        .green,
        .orange {
          display: none;
        }
      }

      .box {
        background-color: #d8d8d8;
        top: 40%;
        width: 40%;
        left: -2.5rem;
        height: 50%;
        width: 2.5rem;
      }

      img {
        height: 50%;
      }
    }
  }
}
