#archivio-eventi {
  .hero {
    background-image: url("/img/calendar-background.jpg");
    height: 440px;
    background-size: cover;
    background-position: center bottom;
    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  .heading {
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }

    a {
      display: flex;
      align-items: center;

      svg {
        margin-left: 0.2rem;
      }
    }
  }

  .eventi-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    .event {
      background: #fff;

      .heading {
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 1rem 2rem;
        text-transform: uppercase;

        span:nth-child(2) {
          font-weight: 600;
        }
      }

      .body {
        height: 180px;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
          p:nth-child(1) {
            font-weight: 600;
            margin-bottom: 1rem;
          }

          p:nth-child(2) {
            font-weight: 400;
            // max-width: 100ch;
            // text-overflow: ellipsis;
            // overflow: hidden;
            margin-bottom: 1rem;
          }
        }

        .bottom {
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;

          .time {
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            font-weight: 600;
            color: #2590e0;
            & > * + * {
              margin-left: 0.5rem;
            }

            svg {
              height: 1rem;
              width: 1rem;

              *:not(:first-child) {
                stroke: #2590e0;
              }
            }
          }
        }
      }
    }
  }
}
