.pagination-section-component {
  .pagination {
    display: flex;
    font-weight: bold;
    align-items: center;
  }

  .paginationItem {
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }

  .inactive {
    opacity: 0.4;
  }

  .active {
    color: black;
    opacity: 1;
  }

  .disabled {
    display: none;
  }

  .break {
    display: none;
  }

  .left-arrow {
    padding-right: 16px;
    font-size: 20px;
    cursor: pointer;
  }

  .right-arrow {
    padding-left: 16px;
    font-size: 20px;
    cursor: pointer;

    a {
      display: flex;
    }
  }
}
