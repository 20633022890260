#sub-category-page {
  .links {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    & > * + * {
      margin-left: 2rem;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
    }
    .link {
      position: relative;
      background-color: #fff;
      height: 350px;
      width: calc(100% / 3);
      padding: 6rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 1rem;
      }
      &:hover {
        background-color: var(--blueColor);
        color: #fff;
      }
      svg:nth-child(1) {
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;
        height: 4rem;
        padding: 1rem;
        background-color: var(--blueColor);
        *:not(:first-child) {
          stroke: #fff;
        }
      }
      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      p {
        line-height: 1.5;
      }
      svg:nth-of-type(2) {
        align-self: flex-end;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        border: 2px solid var(--greyColor);
        transform: rotate(-45deg);
        flex-shrink: 0;
      }
    }
  }
}
