#simple-page {
  .container {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 1rem;
    }
    a {
      color: var(--blueColor);
    }
  }
}
