.search-results-page {
  .no-results {
    text-align: center;
  }

  .search-results-list {
    flex-direction: column;
  }

  .items-results {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
