.link-press-kit-component {
  .boxes {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    & > * + * {
      margin-left: 2rem;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
    }

    .box {
      width: 50%;
      background-color: #fff;
      padding: 2rem;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      & > * + * {
        margin-left: 2rem;
      }

      .box-left {
        &.different {
          * {
            stroke: var(--blueColor);
          }
        }

        svg {
          height: 4rem;
          width: 4rem;
          *:not(:first-child) {
            stroke: var(--blueColor);
          }
        }
      }

      .box-right {
        display: flex;
        justify-content: space-between;
        .box-right-left {
          flex-basis: 80%;

          h2 {
            font-size: 2rem;
            margin-bottom: 1rem;
          }
        }

        svg {
          align-self: flex-end;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          transform: rotate(-45deg);
          padding: 0.6rem;
          border: 2px solid var(--greyColor);
        }
      }
    }
  }
}
