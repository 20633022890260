#filters form {
  display: flex;
  margin-bottom: 2.5rem;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    & > * + * {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
  & > * + * {
    margin-left: 2rem;
  }

  .type {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 2rem;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      & > * + * {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  select {
    border: 2px solid var(--greyDarkColor);
    height: 3rem;
    min-width: 12rem;
    padding: 0 1rem;
    font-size: 0.9rem;
    outline-color: var(--greyDarkestColor);
  }

  .btn {
    height: 3rem;
    border-radius: 3rem;
    font-size: 1rem;
  }

  .reset {
    display: none;
    &.show {
      display: block;
      cursor: pointer;
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
        width: 1.5rem;
      }
    }
  }
}
