#news-media-press-kit-comunicati-stampa {
  h2 {
    margin-bottom: 3rem;
  }
  .comunicati-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    .comunicato {
      display: flex;
      flex-direction: column;
      border-bottom: 2px solid var(--greyColor);
      padding: 1rem 0;
      height: 200px;
      justify-content: space-between;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border-top: 2px solid var(--greyColor);
      }
      h3 {
        padding: 1rem 0;
        font-size: 1.2rem;
        font-weight: 600;
      }
      .btn {
        align-self: flex-end;
      }
      .date {
        color: var(--greyDarkestColor);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8rem;
      }
    }
  }
}
