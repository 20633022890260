.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  // height: 100%;
  padding-top: 6rem;
  padding-bottom: 3rem;
  flex: 1;

  .spinner {
    border: 6px solid #fff;
    border-top: 6px solid var(--blueColor);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    @media screen and (max-width: 768px) {
      width: 30px;
      height: 30px;
      border-width: 6px;
    }
    &.darker {
      border-color: var(--greyDarkColor);
      border-top-color: var(--blueColor);
    }
    &.lighter {
      border-color: #fff;
      border-top-color: var(--greyDarkestColor);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.btn-spinner {
  border: 2px solid #fff;
  border-top: 2px solid var(--blueColor);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
  @media screen and (max-width: 768px) {
    width: 16px;
    height: 16px;
    border-width: 3px;
  }
  &.darker {
    border-color: var(--greyDarkColor);
    border-top-color: var(--blueColor);
  }
  &.lighter {
    border-color: #fff;
    border-top-color: var(--greyDarkestColor);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex: 1;
}
