@media screen and (max-width: 1199px) {
  .video-container {
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 599px) {
  .video-container {
    width: 100%;
    height: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .video-container {
    width: 1200px;
    height: 600px;
  }
}
