.list-categories-component {
  margin-bottom: 3rem;

  .list {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 970px) {
      overflow-y: scroll;
      padding: 0 1rem;
    }
    & > * + * {
      margin-left: 1rem;
    }
    &__spacer {
        background: white;
        width: calc((100% / 6) * 5);
        margin-left: 1rem;
        @media screen and (max-width: 799px) {
            display: none;
            
        }
    }

    .category {
      width: calc(100% / 5);
      height: 200px;
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 1.5rem;
      cursor: pointer;
      @media screen and (max-width: 970px) {
        flex-shrink: 0;
        width: 200px;
      }
      &-big {
        width: 25%;
      }

      svg {
        flex-shrink: 0;
        align-self: flex-end;
        height: 3.5rem;
        // font-size: 3.5rem;
        margin-bottom: 2rem;
      }

      h4 {
        font-size: 1.2rem;
        font-weight: 700;
      }
      &--empty {
        @media screen and (max-width: 970px) {
        display: none;
      }
      }

    }

    .category:nth-child(1):not(.category--sefit):not(.category--empty)  {
      &:hover, &.active {
        background-color: var(--amministrazione);
        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }
      svg *:not(:first-child) {
        stroke: var(--amministrazione);
      }
    }

    .category:nth-child(2) {
      &:hover, &.active  {
        background-color: var(--affari);
        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }

      svg *:not(:first-child) {
        stroke: var(--affari);
      }
    }

    .category:nth-child(3) {
      &:hover, &.active  {
        background-color: var(--lavoro);
        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }

      svg *:not(:first-child) {
        stroke: var(--lavoro);
      }
    }

    .category:nth-child(4) {
      &:hover, &.active {
        background-color: var(--giuridico);
        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }

      svg *:not(:first-child) {
        stroke: var(--giuridico);
      }
    }

    .category:nth-child(5) {
      &:hover, &.active  {
        background-color: var(--strategie);

        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }

      svg *:not(:first-child) {
        stroke: var(--strategie);
      }
    }
    .category:nth-child(6):not(.category--sefit):not(.category--empty) {
      &:hover, &.active  {
        background-color:  #0066a8;

        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }

      svg *:not(:first-child) {
        stroke: var(--comunicazione);
      }
    }
    .category:nth-child(7) {
      &:hover, &.active  {
        background-color:  var(--comunicazione);

        color: #fff;

        svg {
          filter: brightness(60%);
        }
      }

      svg *:not(:first-child) {
        stroke: var(--comunicazione);
      }
    }
    
    .category--sefit {
        height: auto;
        width: 100%;
        @media screen and (min-width:800px) {
            width: calc(100% / 6);
            flex: 1 1 0;
        }
        
        &:hover {
            color: #fff;
            background-color: #1e73be;
        }
      }

  }
  .list--sub {
    .category {
        width: calc(100% / 7);
        height: auto;
        h4 {
        font-size: 1rem;
        font-weight: 700;
      }
      @media screen and (max-width: 970px) {
        flex-shrink: 0;
        width: 150px;
      }
    }

  }
  .expanded {
    padding-top: 1rem;
    @media screen and (max-width: 970px) {
        margin: 0 15px;
      }
    & > h2 {
        color: white;
        padding: 20px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a, span {
            font-size: 1rem;
            margin-left: 10px;
            color: white;
        }
        a {
            transition: opacity .3s;
            &:hover {
                opacity: .6;
            }
        }
    }
    & > div {
        background-color: #fff;
        padding: 10px;
        header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--greyColor);
            img {
                width: 60px;
                height: 60px;
                flex: 0 0 auto;
                object-fit: cover;
                border-radius: 100%;
                margin-right: 15px;
            }
        }
        h3 {
            font-size: 18px;
        }
        p + h3 {
            margin-top: 20px;
        }
    }
    &--sub {
        & > div {
            h2 {
                font-size: 20px;
                a {
                    font-size: 14px;
                }
            }
            h3 {
                font-size: 17px;
            }
        }
    }

    @media screen and (min-width: 800px) {
        & > h2 {
            padding: 40px;
        }
        & > div {
            padding: 40px;
        }
    }
    
  }
}
