.month-header {
  background-color: var(--blueColor);
  color: #fff;
  padding: 12px 32px;
  font-size: 16px;
  text-transform: capitalize;
}

.list-container {
  background-color: #fff;
  padding-left: 32px;
  padding-right: 32px;
}
.single-date {
  padding: 32px 0;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 2px solid var(--greyColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
}
.ultima-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 16px;
  }
}
.ultima {
  padding: 4px;
  box-shadow: 2px 2px 8px #333;
}
