#contatti {
  .contacts-list {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    & > * + * {
      margin-left: 1rem;
      @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
    .organico,
    .contatti {
      width: 50%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      background-color: #fff;
      padding: 2rem;
      h5 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      p {
        line-height: 1.5;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        .bold {
          font-weight: 600;
        }
      }
    }
  }
  .left {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../img/chi-siamo-img.png");
    background-repeat: no-repeat;
    @media screen and (max-width: 768px) {
      flex-basis: 70%;
      height: 300px;
    }
    .blue,
    .green,
    .orange,
    .box {
      position: absolute;
    }
    .blue {
      background-color: #00ccff;
      height: 2.5rem;
      width: 2.5rem;
      top: -1.25rem;
      left: 3rem;
      border-radius: 50%;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .green {
      background-color: #53de69;
      height: 2rem;
      width: 2rem;
      top: 20%;
      left: -1.5rem;
      border-radius: 50%;
      z-index: 1;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .orange {
      background-color: #ff8452;
      height: 2.5rem;
      width: 2.5rem;
      top: 25%;
      right: 3.25rem;
      border-radius: 50%;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    img {
      width: 100%;
    }
  }
}
