#evento {
  .controls {
    padding: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
