#news-media-rassegna-stampa {
  h2 {
    margin-bottom: 3rem;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    .heading-right {
      display: flex;
      & > * + * {
        margin-left: 1rem;
      }
      align-items: center;
      span {
        text-transform: uppercase;
      }
    }
  }
  .rassegna-list {
    display: grid;
    column-gap: 1.5rem;
    row-gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 3rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    .article {
      height: 280px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .sample {
        background-color: var(--sample);
        width: 100%;
        height: 3rem;
        margin-bottom: 1rem;
        flex-shrink: 0;
      }
      .date {
        color: rgb(136, 136, 136);
        font-weight: 500;
        // text-align: end;
        // padding-bottom: 16px;
      }
      h4 {
        padding: 1rem 0;
        height: 110px;
        flex-shrink: 0;
        margin-bottom: 1rem;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
      }
      a {
        align-self: end;
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    & > * + * {
      margin-left: 2rem;
    }
    svg {
      height: 3rem;
      width: 3rem;
      padding: 0.5rem;
      border-radius: 50%;
      border: 2px solid var(--greyDarkColor);
    }
  }
}
