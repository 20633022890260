.navbar {
  position: fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 20;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0 2rem;
  background: rgba(#fff, 0.95);
  @media screen and (max-width: 970px) {
    padding-left: 1rem;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    &.show {
      display: block;
    }
  }

  .logo {
    height: 100%;
    &.none {
      display: none;
    }

    img {
      height: 100%;
      padding: 1rem;
    }
  }

  .navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    & > * + * {
      margin-left: 2rem;
    }

    .search-bar {
      max-width: 200px;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      box-shadow: 0 0 1px rgba(var(--greyColor), 0.5);
      &.extended {
        @media screen and (max-width: 970px) {
          display: none;
        }
      }
    }

    .search-bar-mobile {
      display: none;
      @media screen and (max-width: 970px) {
        display: block;
      }

      .search-bar {
        position: absolute;
        top: 88px;
        left: 0;
        width: 100vw;
        max-width: unset;
        border-radius: unset;
        padding: 1rem;
      }

      img {
        height: 1.2rem;
      }
    }

    .social-list-component {
      @media screen and (max-width: 970px) {
        display: none;
      }
    }

    .navbar-items {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      @media screen and (max-width: 970px) {
        display: none;
      }
      & > * + * {
        margin-left: 2.5rem;
      }
    }

    .line {
      width: 2px;
      height: 100%;
      background-color: var(--greyColor);
      @media screen and (max-width: 970px) {
        display: none;
      }
    }
    @media screen and (max-width: 970px) {
      .social-list {
        display: none;
      }
    }
    .user-menu-div {
        position: relative;
        font-size: 1rem;
        font-weight: 500;
        display: none;
    }
    @media screen and (min-width: 768px) {
      .user-menu-div {
        display: block;
      }
    }
    .user-menu {
        position: absolute;
        background: white;
        border-radius: 0.5rem;
        min-width: 100%;
        padding: 10px;
        top: 100%;
        right: 0;
        z-index: 100;
        box-shadow: 0 5px 10px rgba(0,0,0,0.2);
        margin-top: 40px;
    }
    .navbar-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 2rem;
      & > * + * {
        margin-left: 1.5rem;
      }

      .btn {
        line-height: 3rem;
        height: 3rem;
        border-radius: 3rem;
        padding: 0 2rem;
        font-weight: 500;
        font-size: 1rem;
        z-index: 15;
        @media screen and (max-width: 970px) {
          display: none;
        }
      }

      .hamburger {
        height: 3rem;
        width: 3rem;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid var(--greyColor);
        cursor: pointer;
      }

      svg {
        /* height: 100px; */
        width: 200px;
        z-index: 15;
        position: relative;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }

        line:nth-of-type(1) {
          display: none;
        }

        line:nth-of-type(2),
        line:nth-of-type(3) {
          transform: rotate(0);
          transition: 0.4s;
        }

        line:nth-of-type(2) {
          transform: translateY(20px);
        }

        line:nth-of-type(3) {
          transform: translateY(-20px);
        }
        &.show line:nth-of-type(2) {
          transform: rotate(45deg) translate(65px, -65px);
        }
        &.show line:nth-of-type(3) {
          transform: rotate(-45deg) translate(-130px, 0px);
        }
        &:hover {
          border-color: var(--blueColor);
          background: var(--blueColor);

          line:nth-of-type(2),
          line:nth-of-type(3) {
            stroke: #fff;
          }
        }
      }
    }
  }
}
