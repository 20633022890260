#chi-siamo-utilitalia {
  h6 {
    font-size: 1.4rem;
  }

  .hero {
    height: calc(100vh - 88px);
    background-image: url("/img/chi-siamo.png");
    background-size: cover;
    position: relative;
    @media screen and (max-width: 768px) {
      height: 40rem;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.3);
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      text-align: center;
      width: 50%;
      color: #fff;
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      h1 {
        font-size: 5rem;
        line-height: 1;
        font-weight: 600;
        margin-bottom: 2rem;
        @media screen and (max-width: 992px) {
          font-size: 3rem;
        }
      }

      p {
        width: 70%;
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.5rem;
        margin: 0 auto;
      }
    }
  }

  h2 {
    font-size: 3rem;
    margin-bottom: 3rem;
    font-weight: 300;
    width: 85%;
    @media (max-width: 576px) {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  .list {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 3rem;
    @media screen and (max-width: 576px) {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 3rem;
    }

    ul {
      list-style-type: "· ";
      margin-left: 1rem;

      li {
        line-height: 1.5;
      }
    }
  }

  .row {
    display: flex;
    justify-content: center;
    line-height: 1.5;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    .left {
      width: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("/img/chi-siamo-img.png");
      background-size: cover;
      background-repeat: no-repeat;
      @media screen and (max-width: 768px) {
        flex-basis: 70%;
        height: 300px;
      }

      .blue,
      .green,
      .orange,
      .box {
        position: absolute;
      }

      .blue {
        background-color: #00ccff;
        height: 2.5rem;
        width: 2.5rem;
        top: -1.25rem;
        left: 3rem;
        border-radius: 50%;
      }

      .green {
        background-color: #53de69;
        height: 2rem;
        width: 2rem;
        top: 60%;
        left: -3.5rem;
        border-radius: 50%;
        z-index: 1;
      }

      .orange {
        background-color: #ff8452;
        height: 2.5rem;
        width: 2.5rem;
        bottom: 3rem;
        right: -1.25rem;
        border-radius: 50%;
      }

      .box {
        background-color: #d8d8d8;
        top: 40%;
        left: -2.5rem;
        height: 50%;
        width: 2.5rem;
      }

      img {
        width: 100%;
      }
    }

    .right {
      width: 50%;
      margin-top: 2rem;
      padding-left: 8rem;
      @media screen and (max-width: 768px) {
        flex-basis: 100%;
        padding-left: 0;
      }

      ul {
        list-style-type: "- ";
        margin-left: 1rem;
      }

      h2 {
        line-height: 3rem;
        @media (max-width: 576px) {
          line-height: 2rem;
          margin-top: 2rem;
          font-size: 1.8rem;
        }
      }
    }
  }

  .numbers {
    position: relative;
    background-color: var(--blueColor);
    color: #fff;
    padding: 5rem 0 3rem;
    line-height: 1.5;
    @media screen and (max-width: 768px) {
      padding: 1rem;
    }
    &::after {
      position: absolute;
      z-index: 1;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/img/chi-siamo-numbers.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 200% 20px;
      @media screen and (max-width: 1552px) {
        background-position: 350% 20px;
      }
      @media screen and (max-width: 1386px) {
        background-image: none;
      }
    }

    p {
      width: 40%;
      margin-bottom: 2rem;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .percentages {
      display: flex;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > * + * {
        margin-left: 2rem;
      }
      @media screen and (max-width: 768px) {
        & > *:nth-child(odd) {
          margin-left: 0;
        }
      }

      .percentage {
        @media screen and (max-width: 768px) {
          flex-basis: calc(50% - 1rem);
          margin-bottom: 2.5rem;
        }

        span {
          font-weight: 700;
          &:nth-child(3) {
            font-size: 3rem;
          }
        }
      }
    }
  }

  .our-partners {
    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
}
