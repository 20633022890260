#chi-siamo-organi {
  h2 {
    margin-bottom: 2rem;
  }

  .organi-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 8rem;
    & > * + * {
      margin-top: 3rem;
    }

    .organo {
      display: flex;
      background-color: #fff;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        height: inherit;
      }

      img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
        }
      }

      .left {
        // min-width: 250px;
        // max-width: 250px;
        display: flex;
        @media screen and (max-width: 768px) {
          // min-width: 100%;
          // max-width: 100%;
        }

        .main-info {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding-left: 2rem;

          h2 {
            margin-bottom: 1rem;
          }

          h4,
          h3 {
            font-weight: 500;
          }
        }

        img {
          max-height: 200px;
          max-width: 250px;
        }
      }

      .right {
        padding: 2rem;
        @media screen and (max-width: 768px) {
          padding: 2rem;
        }

        h4 {
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: 400;
          margin-bottom: 1rem;
        }

        h2 {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        h3 {
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 1rem;
          line-height: 1.6rem;
        }
      }
    }
  }

  .organi-full {
    .commissione {
      cursor: pointer;
      background: #fff;
      margin-bottom: 1px;
      padding: 1rem 3rem;
      @media screen and (max-width: 768px) {
        padding: 1rem 1rem;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .number {
          flex-shrink: 0;
          font-size: 2.5rem;
          width: 3rem;
          font-weight: 600;
          color: var(--greyColor);
          @media screen and (max-width: 768px) {
            width: 2rem;
          }
        }

        .left {
          display: flex;
          align-items: center;

          h3 {
            text-transform: uppercase;
            font-size: 1.5rem;
            @media screen and (max-width: 768px) {
              font-size: 1rem;
            }
          }
        }

        svg {
          flex-shrink: 0;
          border-radius: 50%;
          padding: 0.5rem;
          border: 1px solid var(--greyDarkColor);
        }
      }
      &.active {
        svg {
          background: var(--blueColor);
          border-color: var(--blueColor);
          & *:not(:first-child) {
            stroke: #fff;
          }
        }
      }

      .role {
        padding-left: 3rem;
        @media screen and (max-width: 768px) {
          padding-left: 2rem;
        }

        h4 {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          font-weight: 500;
          padding: 1rem 0;
          &::before {
            content: "\2022";
            color: var(--blueColor);
            font-size: 2rem;
            font-weight: bold;
            width: 2rem;
          }
          @media (max-width: 768px) {
            font-size: 1rem;
          }
        }

        .person {
          display: flex;
          align-items: center;
          padding-bottom: 0.5rem;
          margin-left: 2rem;

          h5 {
            font-size: 1rem;
            font-weight: 600;
            @media (max-width: 768px) {
              font-size: 0.9rem;
              margin-right: 16px;
            }

            span {
              text-transform: uppercase;
              font-weight: initial;
            }
          }
        }
      }
    }
  }
}
