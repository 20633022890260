.card-utility {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem;

  .line {
    background-color: rgba(red, 0.2);
    height: 1rem;
  }

  img {
    object-fit: cover;
  }

  h4 {
    @media screen and (max-width: 992px) {
      overflow: hidden;
      font-size: 0.8rem;
      overflow: hidden;
    }
  }

  svg {
    align-self: flex-end;
    padding: 0.5rem;
    font-size: 40px;
    border: 2px solid var(--greyColor);
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    // &:hover {
    //   filter: brightness(80%);
    // }
  }
}
