::-ms-reveal {
  display: none;
}

#login {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  h2,
  h6 {
    text-align: center;
  }
  h2 {
    margin-bottom: 2rem;
  }
  svg.close {
    position: absolute;
    top: 5vh;
    right: 10vw;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    padding: 0.3rem;
    border: 2px solid var(--greyColor);
    cursor: pointer;
    @media screen and (max-width: 768px) {
      right: 5vw;
    }
  }
  svg.eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    color: #00457c;
    // border: 2px solid var(--greyColor);
    cursor: pointer;
    // @media screen and (max-width: 768px) {
    //   right: 5vw;
    // }
  }
  .input {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    & > * + * {
      margin-top: 1rem;
    }
    align-items: center;
    input[type="email"],
    input[type="password"],
    input[type="text"] {
      height: 3rem;
      width: 18rem;
      padding: 0 1rem;
      font-size: 0.9rem;
    }
    input[type="submit"] {
      cursor: pointer;
    }
  }
  .p-alert-message a {
    font-weight: bold;
    text-decoration: underline;

  }
}
