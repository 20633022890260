.card-news {
  background: #fff;
  padding: 1rem 2rem;
  // height: 320px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--greyColor);
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    border-top: 2px solid var(--greyColor);
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    img {
      height: 100%;
      width: 6rem;
      height: 2rem;
    }
  }

  .module {
    height: unset;
  }

  h4 {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-weight: 500;
  }

  .text {
    margin-bottom: 1rem;
  }

  .btn {
    align-self: flex-end;
  }
}
