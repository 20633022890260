#statuto {
  .list {
    p {
      margin-bottom: 2rem;
    }
    & > ul {
      display: flex;
      flex-direction: column;
      & > li {
        cursor: pointer;
        background: #fff;
        margin-bottom: 1px;
        padding: 1rem 3rem;
        font-size: 1.2rem;
        font-weight: 600;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
          padding: 1rem 1rem;
          line-height: 1rem;
          padding-left: 48px;
        }
        &::before {
          vertical-align: middle;
          margin-right: 2rem;
          content: attr(number);
          font-size: 2.5rem;
          width: 3rem;
          font-weight: 600;
          color: var(--greyColor);
          @media screen and (max-width: 768px) {
            margin-right: 1rem;
            margin-left: -32px;
          }
        }

        ul {
          margin-bottom: 2rem;
          display: none;
          font-size: 1rem;
          font-weight: 400;
          margin-left: 2rem;
          margin-top: 1rem;
        }
        &.show ul {
          display: block;
        }
      }
    }
  }
}
