.arrow-left {
  height: 3rem;
  width: 3rem;
  padding: 0.5rem;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: var(--greyDarkColor);
  flex-shrink: 0;
  transition: 0.2s;
  &.prev {
    border-color: rgba(255, 255, 255, 0.4);
    &:hover {
      border-color: #fff;
      * {
        fill: #fff;
      }
    }

    * {
      fill: rgba(255, 255, 255, 0.4);
    }
  }
}

.back svg:hover {
  background-color: var(--blueColor) !important;
  color: white;
  border-color: var(--blueColor) !important;
}
