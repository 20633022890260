.list-faqs {
  .faq {
    background: #fff;
    padding: 1rem 2rem;

    .faq-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .faq-heading-left {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 2.5rem;
        }

        span {
          width: 1rem;
        }

        span {
          color: #dedede;
          font-size: 2rem;
          font-weight: 500;
        }

        h4 {
          color: #000;
          font-size: 1.2rem;
          font-weight: 600;
          @media screen and (max-width: 768px) {
            font-size: 0.9rem;
          }
        }
      }

      svg {
        flex-shrink: 0;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        padding: 0.3rem;
        border: 2px solid var(--greyColor);
        &.active {
          color: #fff;
          background: var(--blueColor);
        }
      }
    }

    p {
      height: 0;
      opacity: 0;
      transition: 0.2s;
      &.active {
        height: 2rem;
        opacity: 1;
      }
    }
    &:not(:last-child) {
      border-bottom: 2px solid var(--greyColor);
    }
  }
}
