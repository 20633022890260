@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Light.eot");
  src: url("Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Light.woff2") format("woff2"), url("Gilroy-Light.woff") format("woff"),
    url("Gilroy-Light.ttf") format("truetype"), url("Gilroy-Light.svg#Gilroy-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Regular.eot");
  src: url("Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Regular.woff2") format("woff2"), url("Gilroy-Regular.woff") format("woff"),
    url("Gilroy-Regular.ttf") format("truetype"),
    url("Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-RegularItalic.eot");
  src: url("Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-RegularItalic.woff2") format("woff2"),
    url("Gilroy-RegularItalic.woff") format("woff"),
    url("Gilroy-RegularItalic.ttf") format("truetype"),
    url("Gilroy-RegularItalic.svg#Gilroy-RegularItalic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Medium.eot");
  src: url("Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Medium.woff2") format("woff2"), url("Gilroy-Medium.woff") format("woff"),
    url("Gilroy-Medium.ttf") format("truetype"),
    url("Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("Gilroy-Bold.eot");
  src: url("Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("Gilroy-Bold.woff2") format("woff2"), url("Gilroy-Bold.woff") format("woff"),
    url("Gilroy-Bold.ttf") format("truetype"), url("Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
