#home {
  .pagination-arrow {
    :hover {
      opacity: 0.5;
    }
  }

  .swiper-wrapper {
    position: relative;
    // height: 70vh;
    // @media screen and (max-width: 768px) {
    //   height: 50vh;
    //   margin-bottom: 0;
    // }
    .swiper-slide {
      width: 100%;
      height: 100%;
      img {
        height: 100%;
        width: 100%;
      }
      // background-size: cover;
      // color: #fff;
      // .description {
      //   padding: 4rem 0;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   height: 100%;
      //   z-index: 1;
      //   position: relative;
      //   width: 1170px;
      //   @media screen and (max-width: 1170px) {
      //     padding: 1rem;
      //   }
      // h1 {
      //   margin-bottom: 2rem;
      //   font-size: 4rem;
      //   font-weight: 700;
      //   @media screen and (max-width: 768px) {
      //     font-size: 2.5rem;
      //     margin-bottom: 1rem;
      //   }
      // }
      // p {
      //   width: 50%;
      //   line-height: 1.5;
      //   font-size: 22px;
      //   @media screen and (max-width: 768px) {
      //     width: 100%;
      //     font-size: 18px;
      //   }
      //   @media screen and (max-width: 576px) {
      //     font-size: 16px;
      //   }
      // }
      // .description-bottom {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;
      //   @media screen and (max-width: 768px) {
      //     padding-bottom: 1rem;
      //   }
      //   .btn {
      //     &:hover {
      //       background: var(--blueColor);
      //       color: #fff;
      //     }
      //     @media screen and (max-width: 768px) {
      //       height: 2rem;
      //       border-radius: 4rem;
      //       padding: 0 2rem;
      //     }
      //   }
      // }
      // }
    }
  }

  .controls {
    position: absolute;
    z-index: 3;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    cursor: pointer;
    svg {
      background: rgba(#000, 0.5);
      & > * {
        fill: #fff;
      }
    }
    & > * + * {
      margin-left: 1.5rem;
      @media screen and (max-width: 768px) {
        margin-left: 1rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    & > .container {
      // padding-top: 0;
    }
  }

  .tiles {
    display: flex;
    margin-bottom: 2rem;
    & > * + * {
      margin-left: 2rem;
      @media screen and (max-width: 768px) {
        margin-left: 0;
        // height: 25vh !important;
      }
    }

    a, div {
      color: #fff;
    }

    .tile {
      width: calc(100% / 3);
      padding: 2rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 1s;
      @media screen and (max-width: 768px) {
        height: 8rem;
        flex-direction: column;
        padding: 1.5rem 1rem;
        height: inherit;
        align-items: flex-start;
        svg {
          margin-bottom: 1rem;
        }
      }
      & > * + * {
        margin-left: 2rem;
        @media screen and (max-width: 768px) {
          margin-top: 1rem;
          margin-left: 0;
        }
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
      &:nth-of-type(1) {
        background: linear-gradient(
          135deg,
          #0066a8 0%,
          #0a85bf 50%,
          #279fca 100%
        );
      }
      &:nth-of-type(1):hover {
        background: linear-gradient(
          315deg,
          #0066a8 0%,
          #0a85bf 50%,
          #279fca 100%
        );
      }
      &:nth-of-type(2) {
        background: linear-gradient(
          135deg,
          #398d99 0%,
          #36b29b 50%,
          #06c96d 100%
        );
      }
      &:nth-of-type(2):hover {
        background: linear-gradient(
          315deg,
          #398d99 0%,
          #36b29b 50%,
          #06c96d 100%
        );
      }
      &:nth-of-type(3) {
        background: linear-gradient(
          135deg,
          #ff8801 0%,
          #ffbd0d 50%,
          #eb650c 100%
        );
      }
      &:nth-of-type(3):hover {
        background: linear-gradient(
          315deg,
          #ff8801 0%,
          #ffbd0d 50%,
          #eb650c 100%
        );
      }
      svg {
        height: 3rem;
        width: 3rem;
        & *:not(:first-child) {
          stroke: #fff;
        }
      }
    }
  }

  .expand {
    padding-bottom: 20px;
    @media screen and (max-width: 970px) {
        margin: 0 15px;
      }
    & > h2 {
        color: white;
        padding: 20px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
            font-size: 1rem;
            margin-left: 10px;
            color: white;
            transition: opacity .3s;
            &:hover {
                opacity: .6;
            }
        }
    }
    & > div {
        background-color: #fff;
        padding: 10px;
        header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--greyColor);
            img {
                width: 60px;
                height: 60px;
                flex: 0 0 auto;
                object-fit: cover;
                border-radius: 100%;
                margin-right: 15px;
            }
        }
        h3 {
            font-size: 18px;
        }
        p + h3 {
            margin-top: 20px;
        }
    }
    @media screen and (min-width: 800px) {
        padding-bottom: 40px;
        & > h2 {
            padding: 40px;
        }
        & > div {
            padding: 40px;
        }
    }
  }

  .board {
    padding-bottom: 1rem;
    display: flex;
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
    }
    & > * + * {
      margin-left: 2rem;
      @media screen and (max-width: 768px) {
        margin-left: 0rem;
      }
    }
    .circolari,
    .atti {
      flex-basis: 50%;
      @media screen and (max-width: 992px) {
        flex-basis: 100%;
        margin-bottom: 2rem;
        margin-left: 0;
      }
    }

    .atti {
      display: flex;
      flex-direction: column;
    }

    .atti-loader {
      flex-grow: 1;
      display: flex;
    }

    .atti-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      // height: 370px;
      justify-content: space-between;
      & > * + * {
        margin-top: 1rem;
      }
      .atto {
        display: flex;
        flex-direction: column;
        height: calc((360px - 1rem) / 2);
        justify-content: space-between;
        & > * + * {
          padding-top: 1rem;
          background-color: #ededed;
          border-bottom: var(--greyDarkColor) 1px solid;
        }
        flex-shrink: 0;

        .content {
          flex-shrink: 0;
          display: flex;
          & > * + * {
            margin-left: 2rem;
          }
          img {
            width: 8rem;
            object-fit: contain;
            height: calc((360px - 2rem) / 2);
          }
          .title {
            flex-basis: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-top: 2px;
            h5 {
              font-size: 1.2rem;
              font-weight: 600;
              @media screen and (max-width: 768px) {
                font-size: 0.9rem;
              }
            }
            button {
              align-self: flex-end;
            }
          }
        }
      }
    }
  }
  .news {
    background: #fff;
    @media screen and (max-width: 768px) {
      // padding: 0 2rem;
    }
    h2 {
      font-size: 3rem;
      @media screen and (max-width: 768px) {
        font-size: 2.2rem;
      }
    }
    .row-news {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > * + * {
        // margin-left: 8rem;
        padding-left: 6rem;
        @media screen and (max-width: 768px) {
          margin-left: 0rem;
          padding-left: 0rem;
        }
      }
      .first-new {
        // flex-basis: 75%;
        flex-basis: 60%;
        display: flex;

        flex-direction: column;
        @media screen and (max-width: 768px) {
          flex-basis: 100%;
          margin-bottom: 2rem;
        }
        .top {
          // & > * {
          //   margin-bottom: 1rem;
          // }
          & > p {
            margin-bottom: 0;
          }
          & > p > p {
            line-height: 1.5;
            margin-bottom: 2rem;
          }
        }
        & > img {
          width: 100%;
          margin-bottom: 2rem;
        }
        span {
          color: var(--greyDarkestColor);
          font-weight: 600;
          font-size: 0.8rem;
          display: block;
        }
        h4 {
          font-size: 1.6rem !important;
          font-weight: 500;
        }
        .btn {
          align-self: flex-start;
          @media screen and (max-width: 576px) {
            align-self: flex-end;
          }
        }
      }
      .list-news {
        flex-basis: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-basis: 100%;
          margin-left: 0;
        }
        .top {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-bottom: 2rem;
          .new {
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid var(--greyColor);
            padding: 1rem 0;
            height: 200px;
            justify-content: space-between;
            .heading {
              display: flex;
              justify-content: space-between;
              align-items: center;

              & > * {
                width: calc(100% / 3);
              }
              img {
                // object-fit: contain;
                height: 100%;
                width: 6rem;
                height: 2rem;
              }
              span {
                &.date {
                  text-align: center;
                }
                &.general {
                  text-align: right;
                }
              }
              h6 {
                margin-bottom: 0;
              }
            }
            h4 {
              padding: 1rem 0;
              font-size: 1.2rem;
              font-weight: 600;
            }
            a {
              align-self: flex-end;
            }
          }
          .new:first-child {
            border-top: 2px solid var(--greyColor);
          }
        }
        .bottom {
          display: flex;
          justify-content: center;
          & > * + * {
            margin-left: 2rem;
          }
        }
      }
    }
  }
  .iniziative {
    .row {
      margin-bottom: 3rem;
    }
    h2 {
      text-align: center;
      @media screen and (max-width: 768px) {
        // padding-top: 40px;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }
    }
    .row {
      padding-bottom: 2rem;
      display: flex;
      height: auto;
      @media screen and (max-width: 768px) {
        // padding: 0 1rem 1rem;
        flex-wrap: wrap;
      }
      & > * + * {
        margin-left: 2rem;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
      .utilities {
        flex: 1;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          margin-bottom: 4rem;
          flex-basis: 100%;
        }
        .list-utilities {
          flex-grow: 1;
          display: grid;
          gap: 2rem;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          @media (max-width: 767px) {
            gap: 1rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
        .utility {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: #fff;
          padding: 1rem;
          .line {
            background-color: rgba(red, 0.2);
            height: 1rem;
          }
          img {
            object-fit: cover;
          }
          h4 {
            @media screen and (max-width: 992px) {
              overflow: hidden;
              font-size: 0.8rem;
              overflow: hidden;
            }
          }
          svg {
            align-self: flex-end;
            padding: 0.5rem;
            font-size: 40px;
            border: 2px solid var(--greyColor);
            border-radius: 50%;
            background-color: #fff;
            cursor: pointer;
            // &:hover {
            //   filter: brightness(80%);
            // }
          }
        }
      }
    }
  }
  .rassegna-section {
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem !important;
    }
    h2 {
      margin-bottom: 3rem;
      text-align: center;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .rassegna {
      display: flex;
      @media screen and (max-width: 768px) {
        // padding: 1rem;
        flex-wrap: wrap;
      }
      h6 {
        width: calc(100% / 4 + 1rem);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      .right {
        width: calc(100% / 4 * 3);
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        .rassegna-list {
          display: flex;
          margin-bottom: 3rem;
          & > div {
            width: calc(100% / 3);
          }
          @media screen and (max-width: 768px) {
            display: flex;
            width: 100%;
            overflow-x: scroll;
          }
          & > * + * {
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

.module {
  overflow: hidden;
  height: 8.4rem; /* exactly three lines */
  @media (max-width: 768px) {
    height: 5.6rem;
  }
}

.fade {
  position: relative;
  height: 100%;
  font-size: 1rem;
  line-height: 1.4rem;
}
.fade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  height: 1.4rem;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 50%
  );
}

.calendario {
  flex: 1 1;
  .heading {
    display: flex;
    justify-content: space-between;
    a {
      display: flex;
      svg {
        margin-left: 0.2rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
  .calendario-eventi {
    .heading {
      background-color: var(--blueColor);
      color: #fff;
      padding: 0.5rem 2.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 2rem;
      @media screen and (max-width: 768px) {
        margin-bottom: 1rem;
      }
      span {
        text-transform: capitalize;
        font-size: 1.3rem;
        font-weight: 300;
      }
      svg {
        font-size: 2rem;
        cursor: pointer;
      }
    }
    .events-list {
      .no-event {
        text-align: center;
        font-size: 1.5rem;
        margin-top: 3rem;
      }
      // margin-top: 1rem;
      // max-height: 500px;
      // max-height: 440px;
      max-height: 340px;
      overflow-y: scroll;

      scrollbar-width: none;
      scrollbar-color: var(--blueColor) #fff;

      &::-webkit-scrollbar {
        width: 0rem;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--blueColor);
      }

      .event {
        cursor: pointer;
        padding: 0.55rem 0;
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 1rem;
        }
        border-bottom: 2px solid #fff;
        &:hover {
          .date,
          .right {
            filter: brightness(80%);
          }
        }
        .date {
          flex-shrink: 0;
          height: 5rem;
          width: 5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // background-color: #07ca6e;
          color: #fff;
          div:nth-child(1) {
            font-size: 1.5rem;
          }
          div:nth-child(2) {
            font-weight: 300;
            font-size: 1rem;
          }
        }
        .right-event {
          h4 {
            font-size: 1rem;
            font-weight: 500;
            padding-bottom: 0.5rem;
            @media screen and (max-width: 768px) {
              font-size: 0.9rem;
            }
          }
          .time {
            color: #2590e0;
            display: flex;
            align-items: center;
            & > * + * {
              margin-left: 0.5rem;
            }
            svg {
              height: 1rem;
              & *:not(:first-child) {
                stroke: #2590e0;
              }
            }
          }
        }
      }
    }
  }
}

.atti {
  flex-basis: 50%;
  @media screen and (max-width: 992px) {
    flex-basis: 100%;
    margin-bottom: 2rem;
    margin-left: 0;
  }
}
.atti {
  display: flex;
  flex-direction: column;
}
.atti-loader {
  flex-grow: 1;
  display: flex;
}
.atti-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  // height: 370px;
  justify-content: space-around;
  // & > * + * {
  //   margin-top: 1rem;
  // }
  .atto {
    display: flex;
    flex-direction: column;
    height: calc((360px - 1rem) / 2);
    justify-content: space-around;
    & > * + * {
      padding-top: 1rem;
      background-color: #ededed;
      border-bottom: var(--greyDarkColor) 1px solid;
    }
    flex-shrink: 0;

    .content {
      flex-shrink: 0;
      display: flex;
      & > * + * {
        margin-left: 2rem;
      }
      @media screen and (max-width: 768px) {
        padding-top: 0.5rem;
      }
      img {
        width: 8rem;
        object-fit: contain;
        height: calc((360px - 2rem) / 2);
      }
      .title {
        flex-basis: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 2px;
        h5 {
          font-size: 1.2rem;
          font-weight: 600;
          @media screen and (max-width: 768px) {
            font-size: 0.9rem;
          }
        }
        button {
          align-self: flex-end;
        }
      }
    }
  }
}
