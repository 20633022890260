#news-media-press-kit {
  .media-kit-section {
    position: relative;
    h2 {
      margin-bottom: 3rem;
    }
    .media-kit-list {
      display: flex;
      & > * + * {
        margin-left: 2rem;
        @media screen and (max-width: 576px) {
          margin-left: 0;
        }
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        & > :nth-child(odd) {
          margin-left: 0;
        }
      }
      .media-kit {
        flex-basis: calc(100% / 4);
        @media screen and (max-width: 768px) {
          flex-basis: calc(50% - 1rem);
        }
        @media screen and (max-width: 576px) {
          flex-basis: 100%;
        }
        margin-bottom: 2rem;
        .content {
          background-color: #fff;
          margin-bottom: 1rem;
          position: relative;
          text-align: center;
          padding: 3rem 3rem 2rem;
          svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 3rem;
            height: 3rem;
            padding: 0.6rem;
            background: var(--blueColor);
            & *:not(:first-child) {
              stroke: #fff;
            }
          }
          img {
            margin: 0 auto;
            height: 12rem;
            margin-bottom: 2rem;
            display: block;
          }
          a {
            display: inline-block;
            margin: 0 auto;
          }
        }
        h4 {
          text-transform: uppercase;
        }
      }
    }
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 0rem;
    }
    .heading-right {
      display: flex;
      & > * + * {
        margin-left: 1rem;
      }
      svg {
        width: 1rem;
      }
    }
  }
  .comunicati-stampa {
    .comunicati-list {
      display: flex;
      padding-bottom: 2rem;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > * + * {
        margin-left: 2rem;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
      .comunicato {
        flex-basis: calc(100% / 3);
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid var(--greyColor);
        border-top: 2px solid var(--greyColor);
        padding: 1rem 0;
        height: 200px;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-basis: 100%;
        }
        h3 {
          padding: 1rem 0;
          font-size: 1.2rem;
          font-weight: 600;
        }
        .btn {
          align-self: flex-end;
        }
      }
    }
    .date {
      color: var(--greyDarkestColor);
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.8rem;
    }
  }
  .video {
    h2 {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 0rem;
    }
    .video-list {
      display: flex;
      padding-bottom: 2rem;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > * + * {
        margin-left: 2rem;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
      .video {
        width: calc(100% / 3);
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 2rem;
        }
        .sample {
          position: relative;
          background: #fff;
          padding: 1rem;
          text-align: center;
          margin-bottom: 2rem;
          .play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: var(--blueColor);
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            padding: 0.7rem;
            svg {
              *:not(:first-child) {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }
}
