.circulars-list-component {
  display: grid;
  gap: 2rem;
  @media screen and (max-width: 976px) {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    gap: 1rem;
  }

  .circular {
    position: relative;
    background-color: #fff;
    padding: 4rem 2rem 2rem;
    @media screen and (max-width: 768px) {
      padding: 3rem 1rem 1rem;
    }

    .pin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--greyColor);
      font-size: 220px;
      @media screen and (max-width: 976px) {
        font-size: 150px;
      }
    }

    .category {
      position: absolute;
      top: 0;
      right: 0;
      height: 2.5rem;
      width: 2.5rem;
      padding: 0.5rem;
      & > * + * {
        stroke: #fff;
      }
    }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h5 {
        // height: 240px;
        overflow: hidden;
        font-size: 1.2rem;
        font-weight: 500;
        @media screen and (max-width: 976px) {
          margin-bottom: 1rem;
          font-size: 1rem;
          // height: 150px;
          overflow: hidden;
        }
      }

      .incipit {
        // height: 50px;
        // overflow: hidden;
        font-size: 0.9rem;
        line-height: 1.5;
        margin-bottom: 4rem;
      }

      a {
        align-self: center;
      }
    }
  }
}
