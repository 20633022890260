.card-general {
  background: #fff;

  .heading {
    display: flex;
    margin-bottom: 3rem;
    justify-content: space-between;
    padding: 1rem 2rem;
    text-transform: uppercase;

    span:nth-child(2) {
      font-weight: 600;
    }
  }

  .body {
    height: 180px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
      p:nth-child(1) {
        font-weight: 600;
        margin-bottom: 1rem;
      }

      p:nth-child(2) {
        font-weight: 400;
        max-width: 100ch;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 1rem;
      }
    }

    .bottom {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;

      .time {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 600;
        color: #2590e0;
        & > * + * {
          margin-left: 0.5rem;
        }

        svg {
          height: 1rem;
          width: 1rem;
          *:not(:first-child) {
            stroke: #2590e0;
          }
        }
      }
    }
  }
}
