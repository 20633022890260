#category-page {
  .hero {
    background-position: center;
    color: #fff;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    // padding: 0 8rem;
    align-items: center;
    height: calc(100vh - 88px);
    @media screen and (max-width: 768px) {
      padding: 2rem;
      height: 250px;
      // width: 100%;
      // background-position: center -30px;
      background-position: center;
    }
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(#000, 0.2);
    }
    .description {
      padding-left: calc((100vw - 1170px) / 2);
      z-index: 5;
      @media screen and (max-width: 768px) {
        display: none;
      }

      h1 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.5;
        padding-bottom: 0.5rem;
      }

      .cit {
        text-align: right;
        text-transform: uppercase;
      }
    }

    .overlay {
      width: 60%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .overlay {
    padding-left: calc((100vw - 1170px) / 2);
    position: absolute;
    bottom: 0;
    z-index: 5;
    left: 0;
    // width: 50%;
    // width: 60%;
    height: 25%;
    display: flex;
    // justify-content: center;
    align-items: center;
    background: var(--ambienteGradient);
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 30%;
    }

    .row {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        align-items: center;
      }
      & > * + * {
        margin-left: 2.5rem;
      }

      .sector {
        text-transform: uppercase;
        transform: rotate(-90deg);
        font-weight: 700;
        font-size: 0.8rem;
      }

      .right {
        display: flex;
        align-items: center;
        font-size: 3rem;
        font-weight: 700;
        @media screen and (max-width: 1144px) {
          font-size: 1.8rem;
        }
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
        & > * + * {
          margin-left: 1rem;
        }

        svg {
          height: 3rem;
          & > *:not(:first-child) {
            stroke: #fff;
          }
        }

        span {
          text-transform: capitalize;

          span {
            text-transform: none;
          }
        }
      }
    }
  }

  .hero2 {
    color: #fff;
    position: relative;
    display: flex;
    background-size: cover;
    align-items: center;
    padding: 0 8rem;
    height: 24rem;

    .row {
      .right {
        font-size: 1.5rem;
        margin-right: 2.5rem;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
          margin-right: 1rem;
          margin-left: 1rem;
        }
      }
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      // width: 50%;
      // width: 100%;
      height: 25%;
      display: flex;
      // justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .section {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 2rem;
          @media screen and (max-width: 768px) {
            margin-left: 1rem;
          }
        }
        & > span:nth-of-type(1) {
          font-size: 1.5rem;
          @media screen and (max-width: 768px) {
          }
        }
        & > span:nth-of-type(3) {
          font-size: 1.5rem;
          font-weight: 600;
          @media screen and (max-width: 768px) {
            font-size: 1rem;
          }
        }

        span {
          text-transform: capitalize !important;
        }

        span {
          text-transform: none;
        }

        svg {
          height: 3rem;
          & > *:not(:first-child) {
            stroke: #fff;
          }
        }
      }
    }
  }

  .cosa-facciamo {
    padding: 5rem 0 2rem;

    h2 {
      font-size: 3rem;
      @media screen and (max-width: 768px) {
        font-size: 2.5rem;
      }
    }
  }

  .features {
    display: flex;
    & > * + * {
      margin-left: 0.1rem;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

      &--more {
        padding-top: 0.1rem; 
      }

    .feature {
      position: relative;
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      height: 20rem;
      // flex: 1 1;
      padding: 2rem;
      transition: 0.4s ease-in-out;
      background-color: #fff;
      @media screen and (max-width: 768px) {
        height: unset;
        &:not(:first-child) {
          margin-top: 1px;
        }
      }

      .number {
        margin-bottom: 1rem;
        font-size: 3rem;
        font-weight: 600;
        color: var(--greyColor);
      }

      .preview {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        svg {
          border-width: 2px;
          border-style: solid;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          padding: 0.5rem;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          &:hover {
            color: #fff;
          }
        }

        h3 {
          align-self: flex-start;
          font-size: 1.5rem;
        }
      }

      &.active {
        background-color: attr(data-color);
        overflow-y: auto;
        @media screen and (max-width: 1096px) {
          justify-content: flex-start;
        }
        &::-webkit-scrollbar {
          width: 0; /* Remove scrollbar space */
          background: transparent; /* Optional: just make scrollbar invisible */
        }

        .number,
        p {
          color: #fff;
        }

        p {
          font-weight: 300;
          line-height: 1.2;
        }
      }
    }
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      & > * + * {
        margin-left: 0;
      }

      .feature {
        width: 100%;
      }
    }
  }

  .boxes-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20%;
    row-gap: 4rem;
    padding: 2rem 0;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .title-box {
        grid-column: 1 / -1;
        font-size: 2rem;
        font-weight: bold;

    }

    .hr-box {
        grid-column: 1 / -1;
        margin: 0;
        background: transparent;
        border: 0;
    }

    .box {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      // background-color: #fff;
      padding: 4rem 2rem 2rem;
      height: 27rem;
      cursor: pointer;
      &:hover {
        // background-color: var(--blueColor);
        color: #fff;
      }
      @media screen and (max-width: 768px) {
        // min-height: 27rem;
        height: unset;
      }

      h3 {
        padding-top: 2rem;
        font-weight: 300;
        font-size: 2.5rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 768px) {
          font-size: 2rem;
        }

        span {
          font-weight: 700;
        }
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
      }

      svg:nth-child(1) {
        height: 5rem;
        padding: 1.2rem;
        // background: var(--blueColor);
        position: absolute;
        top: 0;
        left: 0;
      }

      svg:nth-of-type(2) {
        align-self: flex-end;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        border: 2px solid var(--greyColor);
        transform: rotate(-45deg);
        flex-shrink: 0;
      }
      &--black {
        &:hover {
            color: black;
        }
        .social-list-component {
            gap: 16px;
        }
        .social-list-component a {
            position: relative;
            padding: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity .5s;
            &:hover {
                opacity: .6;
            }
        }
        .social-list-component svg {
            padding: 0;
            fill: black;
            width: 100%;
            height: 100%;
        }
      }
    }
    @media (hover: hover) {
      @media screen and (max-width: 768px) {
        .box:hover {
          color: var(--blackColor);
          background: #fff;
        }
      }
    }
  }
}
