#chi-siamo-struttura {
  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    flex-shrink: 0;
  }

  h2 {
    padding-bottom: 0.5rem;
  }

  .direzione-generale {
    width: 40rem;
    margin: 0 auto;
    background-color: #fff;
    margin-bottom: 4rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .heading {
      padding: 1rem 0;
      text-align: center;
      background-color: #c10001;
      color: #fff;
      font-weight: 600;
    }

    .ceo,
    .staff {
      padding: 2rem 4rem;
      @media (max-width: 768px) {
        padding: 1rem 2rem;
      }
    }

    .ceo {
      border-bottom: 2px solid var(--greyColor);
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 3rem;
      }

      h2 {
        font-size: 2rem;
        font-weight: 600;
        @media screen and (max-width: 576px) {
          font-size: 1.5rem;
        }
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 500;
        @media screen and (max-width: 576px) {
          font-size: 1rem;
        }
      }
    }

    .staff {
      line-height: 1.5;

      strong {
        font-weight: 600;
      }
    }
  }
  .settori {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    & > * + * {
      margin-left: 2rem;
    }
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      & > * + * {
        margin-left: 0;
      }
    }
    & > div {
      background: #fff;
      width: calc(100% / 3);
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 2rem;
      }

      .heading {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        text-transform: uppercase;
        & > * + * {
          margin-left: 1rem;
        }

        svg {
          height: 2rem;
        }
      }

      h2 {
        font-size: 1.5rem;
        font-weight: 600;
      }

      h3 {
        font-size: 1rem;
        font-weight: 500;
      }

      .ceo {
        padding: 1rem 2rem;
        border-bottom: 2px solid var(--greyColor);
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 1rem;
        }

        .sample {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
        }
      }

      .staff {
        padding: 2rem;
        line-height: 1.5;

        strong {
          font-weight: 600;
        }
      }
    }

    .acqua {
      .heading {
        color: #0066a8;

        svg *:not(:first-child) {
          stroke: #0066a8;
        }
      }
    }

    .ambiente {
      .heading {
        color: #36b29b;

        svg *:not(:first-child) {
          stroke: #36b29b;
        }
      }
    }

    .energia {
      .heading {
        color: #ffbd0d;

        svg *:not(:first-child) {
          stroke: #ffbd0d;
        }
      }
    }
  }

  .aree {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 400px));
    gap: 2rem;
    justify-content: center;
    margin-bottom: 4rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    & > div {
      background-color: #fff;

      .heading {
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        text-transform: uppercase;
        & > * + * {
          margin-left: 1rem;
        }

        svg {
          height: 2rem;
        }
      }
    }

    h2 {
      font-size: 1.4rem;
      font-weight: 600;
    }

    h3 {
      font-size: 1rem;
      font-weight: 500;
    }

    .ceo {
      padding: 1rem 2rem;
      border-bottom: 2px solid var(--greyColor);
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 1rem;
      }

      .sample {
        width: 4rem;
        flex-shrink: 0;
        height: 4rem;
        border-radius: 50%;
      }
    }

    .staff {
      padding: 2rem;
      line-height: 1.5;

      strong {
        font-weight: 600;
      }
    }

    .lavoro {
      .heading {
        color: #fd4945;

        svg *:not(:first-child) {
          stroke: #fd4945;
        }
      }
    }

    .giuridico {
      .heading {
        color: #c65efb;

        svg *:not(:first-child) {
          stroke: #c65efb;
        }
      }
    }

    .affari {
      .heading {
        color: #b54a4e;

        svg *:not(:first-child) {
          stroke: #b54a4e;
        }
      }
    }

    .comunicazione {
      .heading {
        color: #7256fd;

        svg *:not(:first-child) {
          stroke: #7256fd;
        }
      }
    }
    .strategie {
      .heading {
        color: #36b29b;

        svg *:not(:first-child) {
          stroke: #36b29b;
        }
      }
    }
  }

  .generale {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 400px));
    gap: 2rem;
    margin-bottom: 4rem;
    justify-content: center;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    & > div {
      background-color: #fff;
      padding-bottom: 1rem;

      .heading {
        background-color: #c10001;
        color: #fff;
        padding: 1rem 0;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        & > * + * {
          margin-left: 1rem;
        }
      }

      .person,
      .staff {
        padding: 2rem;
        line-height: 1.5;
        strong {
          font-weight: 600;
        }
      }

      h4 {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 400;
        margin-top: 20px;
      }

      h2 {
        font-size: 1rem;
        font-weight: 600;
      }

      h3 {
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 1rem;
      }
    }
  }
}
