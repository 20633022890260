#associate {
  .hero {
    background-image: url("/img/chi-siamo.png");
    height: 440px;
    background-size: cover;
    background-position: center bottom;
    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }

  h2 {
    margin-bottom: 3rem;
  }

  .table_associate {
    margin-top: 3rem;
    margin-bottom: 3rem;
    border: 1px solid #00457c;
    @media screen and (max-width: 768px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 0.7rem;
    }
    @media screen and (max-width: 576px) {
      font-size: 0.5rem;
    }
    .table_associate_header {
      background-color: #00457c;
      color: #fff;
    }
    .table_associate_row {
      display: flex;
      flex-direction: row;
      .table_associate_cell {
        flex: 1;
        cursor: pointer;
        text-align: center;
        border-right: 1px solid #a3a3a3;
        padding: 1rem;
        @media screen and (max-width: 768px) {
          padding: 0.5rem;
        }
        @media screen and (max-width: 576px) {
          padding: 0.3rem;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    .table_associate_body {
      .table_associate_row:nth-child(even) {
        background: #00457c20;
      }
    }
  }

  .associate-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 2rem;
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 500px) {
      grid-template-columns: minmax(0, 1fr);
    }

    .associata {
      background: #fff;
      padding: 2rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        margin-bottom: 1rem;
      }

      a {
        align-self: center;
        margin-top: 1rem;
      }
    }
  }
}
