.intro-section-component {
  .hero {
    background-size: cover;
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    padding: 0 8rem;
    height: 370px;
    @media screen and (max-width: 768px) {
      padding: 2rem;
    }

    .overlay {
      padding-left: calc((100vw - 1170px) / 2);
      padding-right: 2rem;
      position: absolute;
      bottom: 0;
      left: 0;
      // width: 50%;
      height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .section {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 2rem;
          @media screen and (max-width: 768px) {
            margin-left: 1rem;
          }
        }

        svg {
          height: 2rem;
          & > *:not(:first-child) {
            stroke: #fff;
          }
        }

        span {
          font-size: 1.5rem;
          @media screen and (max-width: 768px) {
            font-size: 0.9rem !important;
          }
        }

        span:nth-of-type(1) {
          text-transform: capitalize;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        span:nth-of-type(2) {
          text-transform: capitalize;
          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        span:last-child {
          font-weight: 600;
        }
      }
    }
  }
}
