#pagina-non-trovata {
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 3rem;
  }
  a {
    color: var(--blueColor);
    margin-top: 3rem;
    font-size: 1.5rem;
    &:hover {
      text-decoration: underline;
    }
  }
}
