.general-list {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  background: #fff;
  .item {
    padding: 2rem 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--greyColor);
    @media screen and (max-width: 576px) {
      flex-direction: column;
      justify-content: center;
    }
    &:first-child {
      border-top: 1px solid var(--greyColor);
    }
    .date {
      margin-bottom: 1rem;
      display: block;
    }
    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      @media screen and (max-width: 576px) {
        margin-bottom: 1rem;
      }
    }
    .btn.small {
      @media screen and (max-width: 576px) {
        width: 50%;
        align-self: center;
      }
    }
  }
}
