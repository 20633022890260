* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.compatibility-page {
  height: 100vh;
  position: relative;
}

.compatibility-page .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.compatibility-page .content img {
  display: inline-block;
  width: 15rem;
}

.compatibility-page .content h1 {
  line-height: 2;
}
