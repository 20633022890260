.privacy-container {
  p,
  a {
    //     font-size: 1.5rem;
    line-height: 2rem;
  }

  a {
    color: #00a0e9;
    text-decoration: underline;
  }

  h5 {
    font-weight: 500;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  td {
    padding: 0.5rem;
    border: 1px solid #c3c3c3;
  }
}
