#news-media-ultime-notizie {
  .intro {
    color: #fff;
    background-color: var(--blueColor);

    .new {
      .fade:after {
        // #00457c
        width: 30%;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          #00457c 50%
        ) !important;
      }
      display: flex;
      & > * + * {
        margin-left: 2rem;
      }
      margin-bottom: 2rem;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }

      .image {
        height: 400px;
        min-width: 60%;
        object-fit: cover;
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 2rem;
          height: 240px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }

        .heading {
          display: flex;
          justify-content: space-between;
          .date {
            text-transform: uppercase;
            margin-bottom: 2rem;
          }
        }

        h3 {
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 2rem;
        }

        p {
          font-weight: 300;
          margin-bottom: 2rem;
        }

        a {
          align-self: flex-start;
        }
      }
    }

    .navigation-circle {
      svg {
        &:hover {
          background: #fff;
          color: var(--blueColor);
        }
      }
    }
  }
  .news {
    h2 {
      margin-bottom: 2rem;
    }
    .module {
      &.heading {
        height: 2.8rem;
        @media (max-width: 768px) {
          height: 2.8rem;
        }
      }
      @media (max-width: 768px) {
        height: 8.4rem;
      }
    }
    .list-news {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2rem;
      @media screen and (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
      }
      .new {
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 2px solid var(--greyColor);
        padding: 1rem 0;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          border-top: 2px solid var(--greyColor);
        }
        @media screen and (max-width: 1170px) {
          height: 360px;
        }
        @media screen and (max-width: 992px) {
          height: 320px;
        }

        .heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          img {
            height: 100%;
            width: 6rem;
            height: 2rem;
          }
        }
        h3 {
          font-size: 1.2rem;
          font-weight: 500;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
        }
        .btn {
          align-self: flex-end;
        }
      }
      .new {
        display: flex;
        .new-heading {
          display: flex;
          justify-content: space-between;
          .sample {
            background-color: var(--sample);
            width: 2rem;
            height: 1rem;
          }
          span {
            color: var(--greyDarkColor);
            font-weight: 600;
            font-size: 0.8rem;
          }
          h6 {
            margin-bottom: 0;
          }
        }
        h4 {
          padding-bottom: 1rem;
          font-size: 1.2rem;
          font-weight: 600;
        }
        button {
          align-self: flex-end;
        }
      }
      p {
        img {
          display: none;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
    }
  }
  .rassegna {
    margin-bottom: 2rem;
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .heading-right {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 1rem;
        }
        svg {
          width: 1rem;
        }
      }
    }
    .rassegna-list {
      display: flex;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > div {
        width: calc(100% / 3);
        @media screen and (max-width: 768px) {
          width: calc(100% / 1);
          margin-bottom: 16px;
        }
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > * + * {
        margin-left: 1.5rem;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }
      margin-bottom: 3rem;
      .article {
        @media (max-width: 1023px) {
          min-width: calc(50% - 16px);
          margin-right: 16px;
          margin-bottom: 16px;
        }
        @media (max-width: 767px) {
          min-width: 100%;
          margin-bottom: 16px;
        }
      }
      // .article {
      //   width: calc(100% / 4 - 1.5rem);
      //   height: 200px;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   @media screen and (max-width: 768px) {
      //     width: 100%;
      //     height: 220px;
      //     margin-bottom: 1rem;
      //   }
      //   .sample {
      //     background-color: rgba(red, 0.2);
      //     width: 100%;
      //     height: 3rem;
      //     margin-bottom: 1rem;
      //     flex-shrink: 0;
      //   }
      //   h4 {
      //     padding: 1rem 0;
      //     height: 110px;
      //     flex-shrink: 0;
      //     margin-bottom: 1rem;
      //     border-top: 2px solid #fff;
      //     border-bottom: 2px solid #fff;
      //   }
      //   a {
      //     align-self: end;
      //   }
      // }
    }
  }
  .links {
    display: flex;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }
    & > * + * {
      margin-left: 2rem;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
    }
    .link {
      position: relative;
      background-color: #fff;
      height: 350px;
      width: calc(100% / 3);
      padding: 6rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 1rem;
      }
      &:hover {
        background-color: var(--blueColor);
        color: #fff;
      }
      svg:nth-child(1) {
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;
        height: 4rem;
        padding: 1rem;
        background-color: var(--blueColor);
        *:not(:first-child) {
          stroke: #fff;
        }
      }
      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
        font-weight: 400;
        span {
          font-weight: 600;
        }
      }
      p {
        line-height: 1.5;
      }
      svg:nth-of-type(2) {
        align-self: flex-end;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        border: 2px solid var(--greyColor);
        transform: rotate(-45deg);
        flex-shrink: 0;
      }
    }
  }
}
