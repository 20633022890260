.strategic-themes {
  margin-bottom: 2rem;
  .heading {
    background-color: var(--blueColor);
    color: #fff;
    padding: 0.5rem 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 970px) {
      overflow-y: scroll;
      padding: 0 1rem;
    }
    & > * + * {
      margin-left: 1rem;
    }

    .category {
      width: calc(100% / 5);
      height: 200px;
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 1.5rem;
      cursor: pointer;
      @media screen and (max-width: 970px) {
        flex-shrink: 0;
        width: 200px;
      }

      svg {
        flex-shrink: 0;
        align-self: flex-end;
        height: 3.5rem;
        // font-size: 3.5rem;
        margin-bottom: 2rem;
      }

      h4 {
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }
}
