.chi-siamo-brochure-page {
  .brochures-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 3rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .brochure {
      background-color: #fff;
      position: relative;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      @media (max-width: 768px) {
        padding: 0;
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;
        height: 4rem;
        padding: 0.8rem;
        background: var(--blueColor);
        & *:not(:first-child) {
          stroke: #fff;
        }
      }

      img {
        display: block;
        margin: 0 auto;
        max-height: 100%;
        height: 240px;
      }

      p {
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 600;
      }

      a {
        align-self: center;
      }

      .file {
        @media (max-width: 769px) {
          padding: 0 1.2rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
