.navigation-circle {
  margin: 2rem 0 3rem;
  display: flex;
  justify-content: center;
  & > * + * {
    margin-left: 2rem;
  }

  svg {
    cursor: pointer;
    // background: #fff;
    height: 3rem;
    width: 3rem;
    padding: 0.5rem;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    flex-shrink: 0;
    transition: 0.2s;
    &:hover {
      // filter: brightness(80%);
      background-color: var(--blueColor);
      color: white;
      border-color: var(--blueColor) !important;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
