#chi-siamo-partner {
  .container {
    @media screen and (max-width: 768px) {
      padding: 2rem;
    }

    img {
      margin-bottom: 1rem;
    }

    .item:nth-child(2),
    .item:nth-child(3),
    .item:nth-child(4) {
      width: calc(100% / 3 - 2rem);
      display: inline-block;
      padding: 1.5rem;
      vertical-align: top;
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0rem;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
