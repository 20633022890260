#evento {
  .heading {
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    & > * {
      width: calc(100% / 3);
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 768px) {
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .general {
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      & > * + * {
        margin-left: 1rem;
      }
      svg {
        height: 3rem;
        width: 3rem;
        padding: 0.5rem;
      }
    }
    .add {
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        & > * + * {
          margin-left: 0.5rem;
        }
        align-items: center;
        svg {
          font-size: 1.2rem;
        }
      }
    }
    .details {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 768px) {
        justify-content: center;
        margin-top: 1rem;
      }
      align-items: center;
      & > * + * {
        margin-left: 1rem;
      }
      .time {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 0.5rem;
        }
        font-size: 0.8rem;
        font-weight: 500;
        svg {
          height: 0.9rem;
          width: 0.9rem;
        }
      }
    }
  }
  .content {
    h1 {
      font-weight: 500;
      margin-bottom: 3rem;
    }

    .sample {
      height: 32rem;
      background: var(--sample);
      content: "";
      margin-bottom: 3rem;
    }

    p {
      line-height: 1.8rem;
      font-size: 1.3rem;
      margin-bottom: 3rem;

      a {
        color: var(--blueColor);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .share {
      display: flex;
      justify-content: center;
      & > * + * {
        margin-left: 1.5rem;
      }
      align-items: center;
      .social {
        display: flex;
        align-items: center;
        & > * + * {
          margin-left: 1rem;
        }
        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
  h2 {
    margin-bottom: 2rem;
    text-align: center;
  }
  p {
    margin-bottom: 2rem;
  }
  .form {
    text-align: center;
    margin-bottom: 2rem;
    .input {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 1rem;
      }
      align-items: center;
      input[type="text"],
      input[type="email"],
      input[type="phone"] {
        border-color: var(--greyDarkColor);
        background: inherit;
        height: 3rem;
        width: 24rem;
        padding: 0 1rem;
        font-size: 0.9rem;
      }
    }
    label {
      margin: 0 auto 2rem;
      width: 24rem;
      display: flex;
      align-items: center;
      @media screen and (max-width: 576px) {
        width: 18rem;
      }
      input[type="checkbox"] {
        margin-right: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        appearance: none;
        background: inherit;
        border: 2px solid var(--greyDarkColor);
        &:checked:after {
          content: "✓";
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    input[type="submit"] {
      cursor: pointer;
      margin: 0 auto;
    }
  }
  .video {
    padding-bottom: 36px;
    justify-content: center;
    display: flex;
    width: 60%;
    height: 400px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
