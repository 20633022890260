.intro-chi-siamo-component {
  color: #fff;
  position: relative;
  background-image: url("/img/chi-siamo.png");
  background-size: cover;
  background-position: 0 bottom;
  display: flex;
  align-items: center;
  padding: 0 8rem;
  height: 24rem;
  @media screen and (max-width: 768px) {
    height: 18rem;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    height: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    & > * + * {
      margin-left: 1rem;
    }

    .innerText {
      display: flex;
      align-items: center;
      padding-left: calc((100vw - 1170px) / 2);
      font-size: 1.2rem;

      .category {
        padding-right: 1rem;
      }

      .dot {
        font-size: 3rem;
        opacity: 0.5;
        padding-right: 1rem;
      }
    }
  }
}
