#answers-page {
  .container {
    max-width: 970px;

    h2 {
      margin-bottom: 3rem;
    }

    p {
      line-height: 1.2;
    }
  }

  .styledText > span,
  .styledText > p {
    color: var(--black);
    font-family: "Gilroy", sans-serif !important;
    line-height: 1.5rem !important;
  }
  .print-padding {
    @media print {
      padding: 0 5%;
      margin-top: -120px;
    }
  }
}
