.intro-press-kit {
  color: #fff;
  position: relative;
  background-image: url("/img/press-kit.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0 8rem;
  height: 24rem;

  .overlay {
    position: absolute;
    padding-left: calc((100vw - 1170px) / 2);
    background-color: var(--blueColor);
    bottom: 0;
    left: 0;
    width: 50%;
    height: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 768px) {
      width: 100%;
    }
    & > * + * {
      margin-left: 1rem;
    }

    span {
      font-size: 1.5rem;
    }
  }
}
