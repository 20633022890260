#video {
  .heading {
    padding-bottom: 1rem;
    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin-left: 1rem;
      }
    }
  }
  h1 {
    margin-bottom: 4rem;
  }
  p {
    margin-bottom: 2rem;
  }
  .btn {
    width: 200px;
  }
  .video {
    & > div {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 320px;
      background: #000;
      svg {
        cursor: pointer;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
    embed {
      display: block;
      height: 500px;
      margin: 0 auto;
      width: 100%;
    }
  }
}
