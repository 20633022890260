.social-list-component {
  display: flex;
  & > * + * {
    margin-left: 0.2rem;
  }

  a {
    color: #000;
    padding: 0 16px 0 8px;
    &:hover {
      color: var(--blueColor);
    }
  }
}
