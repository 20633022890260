#news-media-press-kit-comunicati-stampa {
  .video-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    .video {
      .sample {
        position: relative;
        background: #fff;
        padding: 1rem;
        text-align: center;
        margin-bottom: 2rem;
        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: var(--blueColor);
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          padding: 0.7rem;
          svg {
            *:not(:first-child) {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}
