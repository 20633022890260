#contatti {
  .hero {
    background-image: url("/img/chi-siamo.png");
    height: 450px;
    background-size: cover;
    background-position: center bottom;
    @media screen and (max-width: 768px) {
      height: 200px;
    }
  }
  h2 {
    margin-bottom: 2rem;
  }
  .boxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    @media screen and (max-width: 976px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .box {
      background-color: #fff;
      height: 12rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      h4 {
        padding-bottom: 8px;
        text-transform: uppercase;
      }
      p {
        line-height: 1.4rem;
        font-size: 16px;
      }
    }
  }
}
