.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
}

.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
  @media (max-width: 767px) {
    padding: 16px;
  }
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
    & > * {
      margin-bottom: 0.5rem;
    }
  }
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  //   font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  //   font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838f93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #2ce080;

  color: #fff;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3a4649;

  background-color: transparent;

  color: #2ce080;
}

.cookie-consent-banner__cta:hover {
  background-color: #00457c;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838f93;

  background-color: transparent;

  color: #00457c;
}

.cookie-consent-banner__cta:last-child {
  margin-left: 16px;
}

.overlay-banner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 50%;
  position: relative;
  @media (max-width: 767px) {
    width: 98%;
  }
  //   transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 2%;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}
.popup .close:hover {
  color: #00457c;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

.switch-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e6e6e6;
  height: 60px;
  @media screen and (max-width: 767px) {
    height: 75px;
  }
  & .right-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .show-description {
      font-size: 0.9rem;
      cursor: pointer;
      &:hover {
        color: #00457c;
      }
    }
  }
  .cookie-type {
    font-size: 1.2rem;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 0.9rem;
      width: 100px;
      margin-right: 4px;
      // background-color: #2ce080;
    }
  }
}

.toggle-section {
  padding: 0 0 1rem 0;
}
