#footer {
  margin-top: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 25rem;
  @media screen and (max-width: 992px) {
    height: inherit;
  }

  .footer-top {
    display: flex;
    flex-grow: 1;
    @media screen and (max-width: 970px) {
      flex-wrap: wrap;
      height: inherit;
    }

    .footer-logo {
      text-align: center;
      width: 20%;
      border-right: 1px solid var(--greyColor);
      padding: 2rem;
      @media screen and (max-width: 992px) {
        width: 100%;
      }

      img {
        width: 80%;
        @media screen and (max-width: 992px) {
          width: 40%;
        }
        @media screen and (max-width: 576px) {
          width: 80%;
        }
      }
    }

    .footer-menu {
      width: 80%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 970px) {
        width: 100%;
        height: inherit;
        flex-wrap: wrap;
      }

      .menu {
        flex-grow: 1;
        @media screen and (max-width: 991px) {
          width: 25%;
        }
        @media screen and (max-width: 767px) {
          width: 50%;
        }
        @media screen and (max-width: 575px) {
          width: 100%;
        }

        h6 {
          padding: 1.5rem 0;
          border-bottom: 1px solid var(--greyColor);
          margin-bottom: 0;
          @media screen and (max-width: 970px) {
            padding: 1rem 2.5rem;
            border-bottom: none;
            margin-bottom: 0;
          }
        }

        .voices {
          padding: 1rem 0;
          font-size: 0.9rem;
          display: flex;
          flex-direction: column;
          line-height: 1.5;
          & > * + * {
            margin-top: 0.5rem;
          }
          @media screen and (max-width: 970px) {
            padding: 1rem 2.5rem;
            display: flex;
            flex-direction: column;
          }
        }
        &:first-child {
          h6 {
            padding-left: 3rem;
            @media screen and (max-width: 970px) {
              padding: 1rem 2.5rem;
            }
          }

          .voices {
            padding-left: 3rem;
            @media screen and (max-width: 970px) {
              padding: 1rem 2.5rem;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--greyColor);
    font-size: 0.8rem;

    @media screen and (max-width: 970px) {
      display: flex;
      flex-direction: column;
    }
    p,
    a {
      text-transform: uppercase;
      @media screen and (max-width: 970px) {
        font-size: 0.7rem;
      }
    }

    .footer-bottom-left {
      display: flex;
      @media screen and (max-width: 970px) {
        margin-bottom: 12px;
      }
    }

    .footer-bottom-right {
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 1rem;
      }

      img {
        height: 2rem;
        @media screen and (max-width: 970px) {
          height: 1rem;
        }
      }

      // p {
      //   @media screen and (max-width: 970px) {
      //     display: none;
      //   }
      // }
    }
  }
}
